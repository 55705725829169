"use client";

import { useEffect } from "react";

export function FavIcon() {
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const updateTheme = () => {
      const faviconLink =
        document.querySelector<HTMLLinkElement>("link[rel*='icon']");

      if (!faviconLink) {
        return;
      }

      faviconLink.href = `/img/logo.svg?t=${new Date().getTime()}`;
    };

    // Initial setting
    updateTheme();

    // Attach event listener
    mediaQuery.addEventListener("change", updateTheme);

    return () => mediaQuery.removeEventListener("change", updateTheme);
  }, []);

  return null;
}
