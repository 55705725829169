import type { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

type CreateEnvOptions = Parameters<typeof createEnv>[0];

export const getEnvOptions = () =>
  ({
    shared: {
      VERCEL_URL: z
        .string()
        .optional()
        .transform(v => (v ? `https://${v}` : undefined)),
      PORT: z.coerce.number().default(3000),
      NODE_ENV: z
        .enum(["development", "production", "test"])
        .default("development"),
      LOCAL_PROXY_URL: z.string().optional()
    },
    /**
     * Specify your server-side environment variables schema here. This way you can ensure the app isn't
     * built with invalid env vars.
     */
    server: {
      ENVIRONMENT: z
        .enum(["local", "develop", "qat", "uat", "production", "test"])
        .default("local"),
      DATABASE_URL: z.string().url().min(1),
      DIRECT_URL: z.string().url().min(1),
      CRM_PRIVATE_API_KEY: z.string().min(1),
      BRITISH_GAS_CLIENT_ID: z.string().min(1),
      APERTURE_API_KEY: z.string().min(1),
      ELECTRALINKS_API_KEY: z.string().min(1),
      ELECTRALINKS_API_PASSWORD: z.string().min(1),
      DATA8_PRIVATE_API_KEY: z.string().min(1),
      COMPANIES_HOUSE_PRIVATE_API_KEY: z.string().min(1),
      SUPABASE_SERVICE_ROLE_KEY: z.string().min(1),
      TWILIO_ACCOUNT_SID: z.string().min(1),
      TWILIO_API_KEY: z.string().min(1),
      TWILIO_API_SECRET: z.string().min(1),
      TWILIO_AUTH_TOKEN: z.string().min(1),
      TWILIO_TRANSCRIBE_SERVICE_SID: z.string().min(1),
      TWILIO_TWIML_APP_SID: z.string().min(1),
      TWILIO_ALLOW_INCOMING_CALLS: z.literal("true").or(z.literal("false")),
      TWILIO_LOGS_DAYS_OFFSET: z.string().optional(),
      INNGEST_SIGNING_KEY: z.string().min(1),
      INNGEST_EVENT_KEY: z.string().min(1),
      ENABLE_SOURCE_MAPS: z
        .literal("true")
        .or(z.literal("false"))
        .default("false"),
      UPSTASH_REDIS_REST_URL: z.string().url().min(1),
      UPSTASH_REDIS_REST_TOKEN: z.string().min(1),
      EXPERIAN_USERNAME: z.string().min(1),
      EXPERIAN_PASSWORD: z.string().min(1),
      EXPERIAN_CLIENT_ID: z.string().min(1),
      EXPERIAN_CLIENT_SECRET: z.string().min(1),
      SENDGRID_API_KEY: z.string().min(1),
      TEST_EMAIL: z.literal("true").or(z.literal("false")).default("false"),
      UDCORE_API_KEY: z.string().min(1),
      UDCORE_USER: z.string().min(1)
    },
    /**
     * Specify your client-side environment variables schema here.
     * For them to be exposed to the client, prefix them with `NEXT_PUBLIC_`.
     */
    client: {
      NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
      NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
      NEXT_PUBLIC_FROM_NUMBER: z.string().min(1),
      NEXT_PUBLIC_SOUTH_AFRICA_EON_HUNT_GROUP: z.string().min(1),
      NEXT_PUBLIC_SOUTH_AFRICA_OPUS_HUNT_GROUP: z.string().min(1),
      NEXT_PUBLIC_SOUTH_AFRICA_EON_DFV_HUNT_GROUP: z.string().min(1),
      NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
      NEXT_PUBLIC_POSTHOG_HOST: z.string().url(),
      NEXT_PUBLIC_SITE_URL: z.string().url().optional()
    },
    /*
     * Due to how Next.js bundles environment variables on Edge and Client,
     * we need to manually destructure them all to make sure all are included in bundle.
     *
     * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
     */
    runtimeEnv: {
      ENVIRONMENT: process.env.ENVIRONMENT,
      LOCAL_PROXY_URL: process.env.LOCAL_PROXY_URL,
      VERCEL_URL: process.env.VERCEL_URL,
      PORT: process.env.PORT,
      DATABASE_URL: process.env.DATABASE_URL,
      DIRECT_URL: process.env.DIRECT_URL,
      CRM_PRIVATE_API_KEY: process.env.CRM_PRIVATE_API_KEY,
      NODE_ENV: process.env.NODE_ENV,
      BRITISH_GAS_CLIENT_ID: process.env.BRITISH_GAS_CLIENT_ID,
      APERTURE_API_KEY: process.env.APERTURE_API_KEY,
      ELECTRALINKS_API_KEY: process.env.ELECTRALINKS_API_KEY,
      ELECTRALINKS_API_PASSWORD: process.env.ELECTRALINKS_API_PASSWORD,
      DATA8_PRIVATE_API_KEY: process.env.DATA8_PRIVATE_API_KEY,
      COMPANIES_HOUSE_PRIVATE_API_KEY:
        process.env.COMPANIES_HOUSE_PRIVATE_API_KEY,
      SUPABASE_SERVICE_ROLE_KEY: process.env.SUPABASE_SERVICE_ROLE_KEY,
      TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
      TWILIO_API_KEY: process.env.TWILIO_API_KEY,
      TWILIO_API_SECRET: process.env.TWILIO_API_SECRET,
      TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN,
      TWILIO_TRANSCRIBE_SERVICE_SID: process.env.TWILIO_TRANSCRIBE_SERVICE_SID,
      TWILIO_TWIML_APP_SID: process.env.TWILIO_TWIML_APP_SID,
      TWILIO_ALLOW_INCOMING_CALLS: process.env.TWILIO_ALLOW_INCOMING_CALLS,
      TWILIO_LOGS_DAYS_OFFSET: process.env.TWILIO_LOGS_DAYS_OFFSET,
      INNGEST_SIGNING_KEY: process.env.INNGEST_SIGNING_KEY,
      INNGEST_EVENT_KEY: process.env.INNGEST_EVENT_KEY,
      ENABLE_SOURCE_MAPS: process.env.ENABLE_SOURCE_MAPS,
      UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
      UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
      EXPERIAN_USERNAME: process.env.EXPERIAN_USERNAME,
      EXPERIAN_PASSWORD: process.env.EXPERIAN_PASSWORD,
      EXPERIAN_CLIENT_ID: process.env.EXPERIAN_CLIENT_ID,
      EXPERIAN_CLIENT_SECRET: process.env.EXPERIAN_CLIENT_SECRET,
      UDCORE_API_KEY: process.env.UDCORE_API_KEY,
      UDCORE_USER: process.env.UDCORE_USER,
      NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
      NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
      NEXT_PUBLIC_FROM_NUMBER: process.env.NEXT_PUBLIC_FROM_NUMBER,
      NEXT_PUBLIC_SOUTH_AFRICA_EON_HUNT_GROUP:
        process.env.NEXT_PUBLIC_SOUTH_AFRICA_EON_HUNT_GROUP,
      NEXT_PUBLIC_SOUTH_AFRICA_OPUS_HUNT_GROUP:
        process.env.NEXT_PUBLIC_SOUTH_AFRICA_OPUS_HUNT_GROUP,
      NEXT_PUBLIC_SOUTH_AFRICA_EON_DFV_HUNT_GROUP:
        process.env.NEXT_PUBLIC_SOUTH_AFRICA_EON_DFV_HUNT_GROUP,
      NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
      NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
      SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
      TEST_EMAIL: process.env.TEST_EMAIL
    },
    skipValidation: !!process.env.CI || !!process.env.SKIP_ENV_VALIDATION
  }) satisfies CreateEnvOptions;
