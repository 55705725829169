import(/* webpackMode: "eager", webpackExports: ["FavIcon"] */ "/vercel/path0/apps/crm/src/app/fav-icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/crm/src/app/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/apps/crm/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/apps/crm/src/components/analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyleSwitcher"] */ "/vercel/path0/apps/crm/src/components/style-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TailwindIndicator"] */ "/vercel/path0/apps/crm/src/components/tailwind-indicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/crm/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/apps/crm/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/apps/crm/src/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/crm/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/apps/crm/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-axiom@1.5.1_next@15.0.0-canary.199_@babel+core@7.25.8_react-dom@19.0.0-rc-fb9a90fa48-202_oxt7pxovf67x5zeuro7xtwfese/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.199_@babel+core@7.25.8_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0._jeiravl4ogwkxljtbe3jzzpdky/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.199_@babel+core@7.25.8_react-dom@19.0.0-rc-fb9a90fa48-20240614_react@19.0._jeiravl4ogwkxljtbe3jzzpdky/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@3.7.15_next@15.0.0-canary.199_@babel+core@7.25.8_react-dom@19.0.0-rc-fb9a90f_dolfmwbyhlruhxlhc4sje4mh7u/node_modules/nextjs-toploader/dist/index.js");
