export function isProductionEnvironment(): boolean {
  return process.env.VERCEL_ENV === "production";
}

export function isStagingEnvironment(): boolean {
  return process.env.NODE_ENV === "production" && !!process.env.VERCEL_ENV;
}

export function isLocalEnvironment(): boolean {
  return process.env.NODE_ENV === "development";
}
