import { env } from "../config/env";

type BaseUrlConfig = {
  useProxy: boolean;
  localPort?: string;
};

export function getBaseUrl(config?: BaseUrlConfig): string {
  const { useProxy = true, localPort = "3000" } = config ?? {};

  if (typeof window !== "undefined") {
    return window.location.origin;
  }

  const vercelUrl = env.VERCEL_URL;
  const proxyUrl = env.LOCAL_PROXY_URL;

  if (vercelUrl) {
    return vercelUrl.startsWith("https://")
      ? vercelUrl
      : `https://${vercelUrl}`;
  }

  if (useProxy && proxyUrl) {
    return `https://${proxyUrl}`;
  }

  return `http://localhost:${localPort}`;
}
